

// Icones Robert
.profile-robert--big { 
    @extend .bg-white, .rounded-circle;
    height: 74px;
    width: 74px;
    display: flex;
    justify-content: center;
    > span {
        display: block;
        align-self: center;
        height: 53px;
        width: 50px;
        background: transparent url('../../images/sprite.svg') no-repeat;
        background-size: 50px auto;
        background-position: 0 0;
    }
}



// Structure 
.page-chat { 
    > [class*="container"] {
        height: calc(100vh - 166px);
        display: grid;
        grid-template-rows: auto 1fr;
    }
    @include media-breakpoint-down(lg) { 
        p { 
            font-size: .875rem;
        }
    }
    @include media-breakpoint-up(lg) { 
        > [class*="container"] {
            height: calc(100vh - 236px);
        }
    }
}

// Button exemples questions (fixed)
.toast-robert { 
    position: relative;
    transition: all 0.3s ease-out;
    border-bottom: 1px solid $gray-500;

    @include media-breakpoint-up(lg) { 
        padding: 1rem 3rem;
        text-align: center;

        .btn[data-bs-toggle="offcanvas"] { 
            border-radius: 1rem;
            min-width: 300px;
        }
    }

    @include media-breakpoint-down(lg) { 
        .btn[data-bs-toggle="offcanvas"] { 
            border-radius: 0;
            width: 100%;
        }
    }
}

// Message intro Robert
.body-robert { 
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translate(-50%,0);
    
    @include media-breakpoint-up(lg) { 
        margin: 10px 0;
    }
    @include media-breakpoint-down(lg) { 
        transform: translate(-50%,0) scale(0.8);
        margin-top: -20px;
    }

    ~ blockquote { 
        max-width: 470px;
    }
}

.hand-robert { 
    position: absolute;
    top: 41px;
    left: -40px;
    animation-name: coucou;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 4; 
    // animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
}
@keyframes coucou {
    0% {
        transform-origin: right bottom;
        transform: rotate3d(0, 0, 1, 0deg);
    }
    50% {
        transform-origin: right bottom;
        transform: rotate3d(0, 0, 1, 30deg);
    }
    100% {
        transform-origin: right bottom;
        transform: rotate3d(0, 0, 1, 0deg);
    }
}




// Zone de dialogue
.dialogue-box { 
    overflow-y: auto;
    padding: 1.5rem 1rem 3rem 1rem;

    @include media-breakpoint-up(lg) { 
        padding: 2rem 3rem 3rem 3rem;
    }
}


.dialogue-bot, 
.dialogue-user {
    animation-name: dialogue;
    animation-duration: 0.6s;
    animation-fill-mode: both;
    animation-iteration-count: 1; 
}

.dialogue-bot { 
    animation-delay: .25s;
    p:empty { 
        display: none;
    }
}

@keyframes dialogue {
    0% {
        opacity: 0;
        transform: translate(0,80px);
    }
    100% { 
        opacity: 1;
        transform: translate(0,0);
    }
} 



.bubble { 
    border-radius: 20px;
    padding: .8rem 1rem .7rem 1rem;
    width: 100%;
    position: relative;
}

.dialogue-user { 
    padding-right: 15%;
    margin-bottom: $grid-gutter-width;

    .bubble { 
        background-color: $gray-200;
        border-top-right-radius: 0;
    }

    @include media-breakpoint-up(lg) { 
        padding-right: 20%;
    }
}

.dialogue-bot { 
    padding-left: 0;
    margin-bottom: $grid-gutter-width;
    display: flex;
    gap: 1rem;

    &::before { 
        content:"";
        border-radius: 50%;
        border: 1px solid $primary;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        padding: 6px 7px;
        background: $white url('../../images/sprite.svg') no-repeat;
        background-size: 37px auto;
        background-position: 5.5px 5px;
        position: sticky;
        top: 0;
    }

    .bubble { 
        background-color: $blue-200;
        color: darken($primary, 35%);
        border-top-left-radius: 0;
    }

    @include media-breakpoint-up(lg) { 
        padding-left: 15%;
    }

    &--error { 
        &::before { 
            background-position: 5.5px -141px;
        }
    }
}
/*
.gif-chat {
    position: absolute;
    right: 1rem;
    bottom: .8rem;
}
*/






// Question textaera + BTN + Footer
.send-question { 
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 1px solid $gray-500;
    background-color: $white;

    .form-control { 
        border-radius: 0;
        border-top: none;
        border-bottom: none;
        border-left: none;
        height: 4rem;
        animation: bgAera 2s ease-in 6s 2 normal forwards;

        @include media-breakpoint-up(sm) { 
            border-left: 1px solid $gray-500;
        }
    }
}


@keyframes bgAera {
    0%, 50%, 100% {
        background-color: $white;
    }
    25%, 75% {
        background-color: $secondary;
    }
}


// Arrow
.arrow {
    animation-name: arrow;
    transform-origin: center bottom;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: 6s;
    animation-iteration-count: 3; 
    opacity: 0;
}

@keyframes arrow {
    0%, 20%, 53%, 80%, 100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translate3d(0,0,0);
    }
    40%, 43% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        opacity: 1;
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -15px, 0);
    }
    90% { 
        opacity: 1;
        transform: translate3d(0,-4px,0);
    }
} 




/* BTN send */
#form-send { 
    display: flex;
    align-items: center;
    color: $danger;
    
    > span {
        display: block;
        height: 30px;
        width: 30px;
        background: transparent url('../../images/sprite.svg') no-repeat;
        background-size: 37px 186px;
        background-position: 0 -105px;
    }
}




// Exemples questions 
#offcanvasQuestions {
    .offcanvas-header {
        background-color: $secondary;
    }
    &.offcanvas { 
        --bs-offcanvas-width: 600px;
    }
}

