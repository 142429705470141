

.accroche { 
    line-height: 2.4;
    transition: all 0.5s ease-out;
    > h2 { 
        font-size: 1.3rem;
    }
    > p { 
        font-size: 1.1rem;
        letter-spacing: 0.01rem;
    }
}

.nav-item { 
    flex: 1 1 auto;
}

.nav-link { 
    border-radius: 5px;
    height: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: start;
    &.active { 
        background-color: $primary;
        font-weight: bold;
    }

    @include media-breakpoint-down(lg) {
        padding-left: 1rem;
    }
    @include media-breakpoint-up(lg) {
        // color: $white; 
        justify-content: center;
    }
}

.logo {
    transition: all 0.5s ease-out;
}



@include media-breakpoint-down(lg) {
    header {
        .offcanvas-body {
            background-color: $white;
        }
    }
}


