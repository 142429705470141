
.home-page { 
    overflow-x: hidden;
}

.home-img { 
    min-height: 300px;
    width: 100%;
    background: transparent url('../../images/home-img.svg') no-repeat center -20px;

    @include media-breakpoint-up(md) {
        width: 50vw;
        height: 100%;
        background-position: right -30px top -20px;
    }

    @include media-breakpoint-up(xxl) {
        width: 50vw;
        height: 100%;
        background-position: center top -20px;
    }
}