// Corriger le problème de hauteur 100% (100vh) sur mobile
html {
    height: -webkit-fill-available;
}
body {
    padding: 0;
    min-height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
    body {
        /* The hack for Safari */
        min-height: -webkit-fill-available;
    }
}


.mh-container {
    min-height: calc(100vh - 297px);
}



/* ---- */

main {
    position: relative;
    padding-top: 66px;
    background: transparent url('../../images/bg.png') repeat; /* bg-login.png */

    @include media-breakpoint-up(lg) { 
        padding-top: 136px;
    }
}


// link
a { 
    transition: all 0.3s ease-out;
}

p, li { 
    a:not(.btn, .nav-link, .stretched-link) { 
        @extend .link-offset-2, .link-primary;
    }
}
footer, .box-dark { 
    position: relative;
    z-index: 3;
    a:not(.btn) { 
        @extend .link-primary;
    }
}


// Button
.btn-outline-secondary { 
    color: $dark;
}


.text-muted { 
    --bs-secondary-color : #{$gray-600};
}



// Modal
#limiteBot { 
    .profile-robert--big {
        border: 2px solid $primary;  // $info
    }
    .modal-body { 
        padding-top: 0;
    }
}


// Message client
blockquote {
    text-align: center;
    position: relative;
    padding: 2px 20px 0;
    max-width: 300px;

    p { 
        &:last-of-type { 
            margin-bottom: 0;
        }
    }

    &::before, &::after { 
        position: absolute;
        content: "";
        display: block;
        height: 30px;
        width: 34px;
        background: transparent url('../../images/sprite.svg') no-repeat;
        background-size: 37px auto;
        background-position: 0 -41px;
    }
    &::before { 
        top: -32px;
        left: 0;
    }
    &::after { 
        background-position: 0 -72px;
        bottom: -32px;
        right: 0;
    }
}





