
$font-family-path: '../../fonts';


// -----------------------------------------------------------------------------------------*/
// ---- FONTS -----------------------------------------------------------------------------*/
// ---------------------------------------------------------------------------------------*/

/* Unbuntu - REGULAR */
@font-face {
    font-family: 'ubunturegular';
    src: url('#{$font-family-path}/ubuntu/ubuntu-regular-webfont.woff2') format('woff2'),
         url('#{$font-family-path}/ubuntu/ubuntu-regular-webfont.woff') format('woff'),
         url('#{$font-family-path}/ubuntu/ubuntu-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Unbuntu - BOLD */
@font-face {
    font-family: 'ubuntubold';
    src: url('#{$font-family-path}/ubuntu/ubuntu-bold-webfont.woff2') format('woff2'),
         url('#{$font-family-path}/ubuntu/ubuntu-bold-webfont.woff') format('woff'),
         url('#{$font-family-path}/ubuntu/ubuntu-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} 

/* Unbuntu - ITALIC 
@font-face {
    font-family: 'ubuntuitalic';
    src: url('#{$font-family-path}/ubuntu/ubuntu-italic-webfont.woff2') format('woff2'),
         url('#{$font-family-path}/ubuntu/ubuntu-italic-webfont.woff') format('woff'),
         url('#{$font-family-path}/ubuntu/ubuntu-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */


b, strong, .fw-bold { 
    font-family: 'ubuntubold';
    font-weight: normal !important;
    font-style: normal; 
    text-rendering: optimizeLegibility; 
    -webkit-font-smoothing: antialiased;
}

.fw-normal {
    font-family: 'ubunturegular';
    text-rendering: optimizeLegibility; 
    -webkit-font-smoothing: antialiased;
}
